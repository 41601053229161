/*======== COLOR ========*/
$primary_color: #ec3c66;
$dark_primary: #c34261;
$secondary_grey: #6b707f;
$paragraph_color: #828696;



/*======== FONT ========*/
$lato : 'Lato', sans-serif;
$openSans : 'Open Sans', sans-serif;