$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
// endbower

@import url("http://fonts.googleapis.com/css?family=Lato:300,400,600,700,900");
@import url("http://fonts.googleapis.com/css?family=Open+Sans:400italic,400,600,300,700");
@import "bower_components/fontawesome/scss/font-awesome.scss";
@import "variables.scss";
@import "animate.scss";
@import "mixins.scss";




/* ===========  COMMON ==============*/
body {
  overflow-x: hidden;
  font-family: $openSans;
}
.inside-body-wrapper { overflow: hidden; }
.common-pad { padding: 120px 0; }
.title-head {
  text-align: center;
  margin-bottom: 80px;
  padding-bottom: 10px;
  position: relative;
  &:before, &:after {
    content:''; left: 50%;
    position: absolute;
    height: 0px;
    border-left: 1px dashed #6b707f;
    transition: height 0.8s ease;
  }
  &.animate-before:before { top: -120px; height: 120px; }
  &.animate-after:after { top: 100%; height: 110px; }
  
  h3, h5 { text-transform: uppercase; }
  h5 {
    color: $primary_color;
    font-size: 15px;
    font-family: $lato;
    font-weight: 400;
  }
  h3 {
    color: $secondary_grey;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 40px;
    font-family: $lato;
    font-weight: 400;
    span { font-weight: 700; }
  }
  .fa-angle-down {
    background-color: $primary_color;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #f2f2f2;
    color: #fff;
    padding: 8px 0;
    font-size: 26px;
  }
}//------------------------------------------
@media screen and (max-width: $screen-lg - 1) {
  .common-pad { padding: 60px 0; }
  .title-head {
    margin-bottom: 40px;
    &.animate-before:before { top: -60px; height: 60px; }
    &.animate-after:after { height: 40px; }
  }
}//------------------------------------------
@media screen and (max-width: $screen-md - 1) {
  .title-head {
    &:before { top: -60px; height: 60px; }
    &:after { height: 50px; }
  }
}//------------------------------------------


.login-cart {
  a {
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
    margin-left: 10px;
  }
  i {
    margin-right: 10px;
    font-size: 22px;
    vertical-align: middle;
  }
  .fa-shopping-cart span {
    position: absolute;
    top: -8px; right: -8px;
    font-size: 12px;
    background-color: $primary_color;
    border-radius: 50%;
    width: 16px; height: 16px;
    text-align: center;
    padding: 2px 0;
  }
}
.navbar-nav > li.dropdown {
  position: relative;
  overflow: hidden;
  .inner-nav {
    opacity: 0; 
    a {
      padding-right: 60px;
      &:hover { border: 1px solid $dark_primary; }
    }
  }
  &.open {
    overflow: visible;
    .inner-nav { opacity: 1; }
  }
}


.inner-nav {
  transition: all 0.4s ease;
  padding: 0; z-index: 4;
  height: auto; list-style: none;
  background-color: rgba(0,0,0,0.8);
  a {
    padding: 10px; width: 100%;
    display: inline-block;
    &:hover {
      background-color: $primary_color;
      border: 1px solid $dark_primary;
    }
  }
}//---------------------------------------------
@media screen and (min-width: $screen-md) {
  .inner-nav {
    position: absolute;
    top: 100%; left: 0;
    width: 200px;
    a { border: 1px solid transparent; }
  }
}//---------------------------------------------
@media screen and (max-width: $screen-md - 1) {
  .navbar-nav > li.dropdown {
    .inner-nav {
      max-height: 0px;
      a { border: 1px solid $dark_primary; }
    }
    &.open .inner-nav { max-height: 300px; }
  }
}//---------------------------------------------


// MOBILE NAV MENU APPEAR BEFORE 992 INSTEAD OF 768
@media screen and (min-width: $screen-md) {
  .collapse { display: block!important; }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-property: height, visibility;
    transition-duration: .35s;
    transition-timing-function: ease;
  }
  .nav-menu { float: left; }
  .login-cart, .btn-cart-wrapper { float: right; }
}//---------------------------------------------
@media screen and (max-width: $screen-md - 1) {
  .navbar-toggle { display: block!important; }
  .navbar-nav {
    float: none!important;
    & > li { float: none!important; text-align: right; }
  }
  .navbar-header { float: right; }
  .navbar { float: none!important; }
  .login-cart { float: right; margin-right: 30px; }
}//---------------------------------------------



/*----------- MOBILE MENU ---------------*/
.navbar-toggle {
  border: 1px solid #fff;
  .icon-bar { background-color: #fff; }
}//-------------------------------------------
@media screen and (max-width: $screen-sm - 1) {
  .navbar {
    width: 100%;
    .navbar-nav { text-align: right; padding-right: 15px; }
  }
}//------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
  .nav > li > a { padding: 15px 10px; }
}//----------------------------------------------

@media screen and (max-width: 500px) {
  // .login-cart { float: none; text-align: right; margin-right: 15px; }
  // .navbar-header { float: none; }
  .stick {
    .navbar-header, .login-cart { float: right; }
    &.navbar-wrapper .navbar a { display: block; }
  }
}//----------------------------------------------
@media screen and (max-width: $screen-xs - 1) {
  .navbar-wrapper .navbar a { display: block; }
  // .login-cart a { padding-top: 10px; padding-bottom: 10px; }
}//----------------------------------------------
@media screen and (max-width: $screen-md - 1) {
  .navbar .navbar-nav {
    padding: 0;
    & > li a { padding-right: 30px; }
    .dropdown.open {
      .inner-nav {
        background-color: $dark_primary;
        position: relative;
        width: 100%; border: none;
      }
    }
  }
}//----------------------------------------------




h3{
  &.head {
    text-transform: uppercase!important;
    font-family: $lato!important;
    font-weight: 300!important;
    font-size: 45px!important;
    span { color: $primary_color; font-weight: 600; }
  }
  &.sub-head {
    font-size: 30px;
    font-family: $openSans;
    font-weight: 300;
  }
}

img { max-width: 100%; height: auto; }

.explore-btn {
  background-color: #a7acb8;
  border-radius: 50px;
  padding: 10px 30px;
  min-width: 150px;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;

  overflow: hidden;
  margin-top: 40px;
  position: relative;
  z-index: 0;
  &:after {
    width: 100%;
    height: 0;
    top: 50%;
    left: 50%;
    background: $primary_color;
    opacity: 0;
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  &:hover:after {
    height: 260%;
    opacity: 1;
  }
}

a {
  &:hover { text-decoration: none; }
  &.btn {
    color: #fff;
    &:hover, &:active, &:focus { color: #fff; }
  }
}



.navbar-wrapper .nav > li > a {
  transition: all 0.3s ease-in-out;
  border-left: 1px solid transparent!important;
  border-right: 1px solid transparent!important;
  &:hover  {
    background-color: $primary_color!important; 
    border-left: 1px solid $dark_primary!important;
    border-right: 1px solid $dark_primary!important;
  }
  &:focus {
    background-color: transparent; 
    border-color: transparent;
  }
  &.active {
    &:hover, &:focus { color: #fff; }
  }
}
.navbar-wrapper .nav .open > a {
  background-color: $primary_color!important; 
  border-color: transparent!important;
}


/*---------------- HOME BANNER --------------------*/
.home-banner {
  background-size: cover;
  position: relative;
  & > .container { position: relative; }
  h2 {
    font-family: $lato; font-size: 180px;
    color: $primary_color;
    text-transform: uppercase;
    font-weight: 700; line-height: 0.8;
    letter-spacing: -10px;
    padding: 280px 0 60px;
    span {
      color: #fff;
      &:first-of-type {
        font-size: 14px;
        letter-spacing: 12px;
        line-height: 1;
      }
      &:last-of-type {
        font-size: 78px;
        letter-spacing: 0;
        line-height: 1;
      }
    }
  }
}
.navbar-wrapper {
  position: absolute;
  top: 50px; left: 0;
  width: 100%;
  background: rgba(0,0,0,0.4);
  z-index: 5;
  transition: background 0.3s ease;
  & > .container { position: relative; }
  &.stick {
    position: fixed; top: 0; width: 100%;
    background: rgba(0,0,0,0.8); left: 0;
  }
}
.navbar {
  float: right;
  margin: 0;
  border: none;
  a {
    color: #fff; 
    text-transform: uppercase;
    // font-family: $openSans;
    font-family: $lato;
  }
  li.active > a {
    // color: #fff;
    color: $primary_color;
    font-weight: 600;
    &:hover { color: #fff; }
  }
}

.logo {
  position: absolute;
  z-index: 10;
  top: 0; left: 15px;
  display: inline-block;
  width: 140px; height: 240px;
  background: url('../images/logo.png') #fff center no-repeat;
  h1 { text-indent: -9999999px }
  &:before {
    content: '';
    width: 0; height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid $primary_color;
    position: absolute;
    left: 50%; top: 0;
    transform: translateX(-50%);
  }
}/*-----------------------------------------------*/
@media screen and (max-width: $screen-md - 1) {
  .home-banner h2 { text-align: center; }
}//------------------------------------------
@media screen and (max-width: $screen-sm - 1) {
  .logo {
    left: 15px;
    width: 100px; height: 180px;
    background-size: 80px;
  }
  .home-banner h2 { letter-spacing: -5px; }
}//------------------------------------------
@media screen and (max-width: $screen-xs - 1) {
  .home-banner h2 {
    padding: 220px 0 60px;
    span { display: inline-block!important; }
  }
}//------------------------------------------

.white-logo {
  position: absolute;
  padding: 5px; z-index: 8;
  top: 0; left: 15px;
  width: auto; height: auto;
  width: 87px; height: 104px;
  background: url('../images/logo-white.png') $primary_color center no-repeat;
  h1 { line-height: 0; margin: 0; font-size: 0; }
  &:before { content: initial; }
}


/* --------------- HOMEPAGE BANNER --------------- */
.homepage .home-banner {
  background: url('../images/backgrounds/home-banner.jpg') no-repeat;
  h2 span { display: block; }
}//-------------------------------------------
@media screen and (min-width: $screen-md) {
  .homepage .home-banner { height: 800px; }
  .home-banner h2 { padding: 300px 0 50px; }
  .homepage .home-banner h2 { padding: 300px 0 200px; }
}//-------------------------------------------
@media screen and (min-width: $screen-lg) {
  .homepage .home-banner h2 { padding: 400px 0 200px; }
}//-------------------------------------------


/* --------------- BLOG BANNER --------------- */
.blog .home-banner {
  background: url('../images/backgrounds/blog-banner.jpg') no-repeat;
  h2 {
    font-size: 80px;
    letter-spacing: 0;
  }
}//-------------------------------------------



/*---------- BELOW BANNER ---------------*/
.below-banner {
  background: url('../images/backgrounds/pattern1.jpg');
  .container {
    background-color: #fff;
    position: relative;
  }
  .desc { @include make-md-column(7); }
  h3 {
    color: #545969;
    font-size: 45px;
    font-family: $lato;
    font-weight: 100;
    text-align: center;
    span { color: #bd184a; }
  }
  .desc-img {
    @include make-md-column(5);
    @include make-lg-column(4);
    @include make-lg-column-offset(1);
    padding-top: 10px;
    text-align: center;
  }
}//-------------------------------------------------
@media screen and (min-width: $screen-md){
  .below-banner {
    h3 { transform: translateY(-50%); text-align: left; }
    .desc { padding-left: 50px; }
    .desc-img { text-align: inital; padding-top: 50px; }
    .container { margin-top: -120px; }
  }
}//-------------------------------------------------
@media screen and (max-width: $screen-md - 1){
  .below-banner h3 { margin-top: 30px!important; }
  .tech-used .desc { margin-top: 0!important; }
}//-------------------------------------------------




/*----------- SEARCH + SHARE ----------------*/
.search-share {
  background-color: #f8f8f8;
  padding: 40px 0;
  .desc {
    @include make-md-column(4);
  }
  .share {
    @include make-xs-column(12);
    @include make-sm-column(6);
    @include make-md-column(4);
  }
  .search {
    @include make-xs-column(12);
    @include make-sm-column(6);
    @include make-md-column(4);
  }

  .share {
    text-align: center;
    ul { padding: 0; display: inline-block; margin: 0; }
    li {
      list-style: none;
      float: left;
      margin: 0 8px;
      text-align: center;
    }
    a {
      border-radius: 50%;
      font-size: 20px;
      color: #fff;
      width: 40px; height: 40px;
      display: inline-block;
      padding: 6px 0px;
      position: relative;
      transform: scale(1);
      transition: transform 0.3s ease;
      &:hover, &:focus { transform: scale(1.2); }
      &.twitter {
        background-color: #45b0e3;
        &:before, &:after { border-color: rgba(69,176,227,0.7); }
      }
      &.dribbble {
        background-color: #d97aa5;
        &:before, &:after { border-color: rgba(217,122,165,0.7); }
      }
      &.facebook {
        background-color: #39599f;
        &:before, &:after { border-color: rgba(57,89,159,0.7); }
      }
      &.g-plus {
        background-color: #333333;
        &:before, &:after { border-color: rgba(51,51,51,0.7); }
      }
      &.y-tube {
        background-color: #e70031;
        &:before, &:after { border-color: rgba(231,1,49,0.7); }
      }
    }
  }
  .search { text-align: right; }
  .search-wrapper {
    float: right;
    border-radius: 50px;
    border: 1px solid #b7b7b7;
    width: 80%;
    position: relative;
    .fa-search {
      position: absolute;
      top: 50%; right: 15px;
      transform: translateY(-50%);
      font-size: 25px;
    }
    input {
      padding: 10px 40px 10px 20px;
      border: none;
      background: transparent;
      width: 100%;
      border-radius: 50px;
      &:focus, &:active { outline: none; }
    }
  }
  .desc {
    img, h5 { float: left; }
    img { margin-right: 15px; }
  }
}//----------------------------------------------
@media screen and (max-width: $screen-md - 1){
  .search-share {
    padding: 15px 0;
    .desc, .search, .share { margin: 10px 0; }
    .desc {
      text-align: center;
      img { float: none }
      h5 { display: inline-block; float: none; }
    }
  }
}//--------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md){
  .search-share .share { text-align: left; }
}//------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .search-share .search-wrapper { float: none; margin: 0 auto; }
}//------------------------------------------



/*------------- EXPERTISE SERVICES -------------*/
.expertise-wrapper {
  @include make-sm-column(4);
  @include make-xs-column(4);
  overflow: hidden;
}
.expertise {
  text-align: center;
  background-color: #5E6679;
  position: relative;
  h3 {
    color: #fff; 
    text-transform: uppercase;
  }
  p { font-family: $lato; color: #fff; }
  &:hover {
    & > img { top: -100%!important; }
    .caption { top: 0; }
  }
}//-----------------------------------------------
@media screen and (min-width: $screen-md){
  .expertise {
    height: 300px;
    & > img {
      transform: translate(-50%, -50%);
      left: 50%; position: absolute;
      transition: all 0.5s ease-in-out;
    }
    
    p { margin: 25px 0; }
  }
  .expertise-wrapper .caption {
      padding: 20px;
      position: absolute;
      top: 100%;
      left: 0;
      transition: all 0.4s ease-in-out;
    }
}//-----------------------------------------------
@media screen and (max-width: $screen-md - 1){
  .expertise {
    padding: 25px 15px;
    h3 { margin: 25px 0; }
  }
  .expertise-wrapper {
    padding: 0 5px;
    .caption img { display: none; }
  }
}//---------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .expertise-wrapper { padding: 0 2px; }
}//-----------------------------------------------
@media screen and (max-width: $screen-xs - 1){
  .expertise-wrapper { width: 100%; margin: 5px 0; padding: 0 15px; }
}//----------------------------------------------



/*---------------- TECH ADVERT -------------*/
.tech-used {
  padding: 60px 0;
  background-color: #f4f4f4;
  background: url('../images/backgrounds/technology.jpg') #f4f4f4 center no-repeat;
  background-size: cover;
  .fig {
    @include make-sm-column(5);
    text-align: right;
  }
  .desc { @include make-sm-column(7); }
  h3 {
    color: #545969;
    margin-bottom: 30px;
    &.sub-head { font-size: 45px; }
  }
}//-------------------------------------------
@media screen and (max-width: $screen-lg - 1){
  .tech-used { padding: 40px 0; }
}//----------------------------------------
@media screen and (min-width: $screen-md){
  .tech-used .desc { transform: translateY(-12%); }
}//---------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .tech-used {
    .desc, .fig { text-align: center; }
    .desc { margin-bottom: 30px; }
    .fig img { max-height: 300px; }
  }
}//----------------------------------------



/*---------- OUR PRODUCTS ----------*/
.our-products {
  .expertise {
    height: 500px;
    background-color: #efefef;
    background-size: cover!important;
    position: relative;
  }
  .imgCaption:before {
    position: absolute;
    content: ''; z-index: 1;
    left: 0; top: 0;
    width: 100%; height: 100%;
  }
}//--------------------------------------------
@media screen and (max-width: $screen-lg - 1){
  .our-products .expertise { height: 400px; }
}//------------------------------------------------
@media screen and (min-width: $screen-md){
  .our-products {
    .expertise-wrapper .caption {
      padding: 20px 30px; opacity: 0; z-index: 2;
      h3, p { color: #fff; }
      h3 { margin-bottom: 30px; }
    }
    .imgCaption{
      position: relative;
      &:hover .caption { opacity: 1; top: 0; }
      &:before {
        background: linear-gradient(to bottom, rgba(236,60,102,1) 0%,
                                             rgba(236,60,102,0.7) 70%,
                                             rgba(236,60,102,0.2) 95%,
                                             rgba(236,60,102,0) 100%);
        opacity: 0; transition: opacity 0.4s, transform 0.4s;
        transform: translate3d(0,-50%,0);
      }
      &:hover:before {
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
  }
}//------------------------------------------------
@media screen and (max-width: $screen-md - 1){
  .our-products .expertise {
    height: 300px;
    &:before { background-color: rgba(236,60,102,0.8); }
  }
  .our-products .caption { position: relative; }
}//------------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .our-products .expertise-wrapper {
    margin-bottom: 30px;
    width: 100%;
    .caption { padding: 0 15px; }
  }
}//------------------------------------------------
@media screen and (max-width: $screen-xs - 1){
  .our-products .expertise { height: 200px; }
}//------------------------------------------------



/*----------- ABOUT US --------------*/
.about-us {
  padding: 120px 0 0;
  background-color: #4c4f56;
  background: url('../images/backgrounds/about-us.jpg') #4c4f56 center right no-repeat;
  background-size: cover;
  position: relative;
  .form-btn {
    background-color: $primary_color;
    background: url('../images/about/write-us.png') $primary_color no-repeat;
    text-align: left;
  }
  h3 { color: #8f95a9; line-height: 1.4; }
  .caption, .fig { @include make-sm-column(6); }
}//-------------------------------------------------
@media screen and (max-width: $screen-md - 1){
  .about-us { padding: 40px 0 0; }
}//-----------------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1){
  .about-us .form-btn { padding: 30px 102px 30px 20px !important; }
}//------------------------------------------------
@media screen and (min-width: $screen-sm){
  .about-us {
    .form-btn {
      position: absolute;
      left: 51%; bottom: -50px;
      background-position: center right 25px!important;
      padding: 30px 150px 30px 40px;
    }
    .caption, .fig { float: right!important; }
    .caption { margin-top: 30px; transform: translateY(-50%); }
  }
}//--------------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .about-us {
    text-align: center;
    .form-btn {
      background-position: center right 15px!important;
      padding: 20px 80px 20px 15px;
      margin: 30px 0;
    }
    .fig img { max-height: 300px; }
    .caption { margin-top: 0!important; }
  }
}//------------------------------------------



/*----------- OUR SKILLS ------------*/
.our-skills .graph-wrapper {
  background-color: #f8f8f8;
  & > div { @include make-md-column(6); }
}
.graph-chart { background-color: #f8f8f8; }
.flow-chart {
  background-color: #4a4d54;
  ul { padding: 15px 30px 15px 50px; }
  li {
    padding-left: 30px;
    margin: 15px 0;
    list-style: none;
    position: relative;
    &:before {
      position: absolute;
      color: $primary_color;
      left: -25px; top: 12px;
    }
    &:after {
      content: '';
      height: calc(100% + 18px);
      position: absolute;
      left: -20px; top: 25px;
      border-left: 2px dashed #999;
    }
    &:last-of-type:after { content: initial; }
  }
  h4 {
    color: #fff;
    font-family: $lato;
    font-weight: 400;
    font-size: 20px;
  }
  h5 {
    color: #a7acb8;
    font-family: $lato;
    font-weight: 400;
    line-height: 1.4;
    font-size: 16px;
  }
}//------------------------------------------



/*------------ CIRCULAR PROGRESS BAR -----------*/
@media screen and (max-width: $screen-md - 1){
  .circle-cont, .inner-circle, .inner-inner-circle,
  .inner-inner-circle3, .inner-inner-circle4, .left-bottom { display: none; }

  .left-top { padding: 30px 0; }
  .html-items {
    padding: 5px 10px;
    background-color: #ccc;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;
    overflow: hidden;
    &:after {
      position: absolute;
      height: 100%; top: 0;
      right: 0; content: attr(data-percent);
      padding: 5px 10px;
    }
    & > div {
      left: 0; top: 0;
      width: 0%; height: 100%;
      background-color: $primary_color;
      padding: 5px 10px; color: #fff;
      text-transform: uppercase;
      position: absolute;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
    }
  }
}//----------------------------------------------
@media screen and (min-width: $screen-md) and (max-width: $screen-lg - 1){
  .circle-wrapper { margin: 60px 0; }
}//----------------------------------------------
@media screen and (min-width: $screen-md){
  .circle-wrapper {
    min-height: 600px;
    position: relative;
    overflow: hidden;
  }
  .left {
    min-height: 600px;
    width: 43%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
    background-color: #F8F8F8;
  }
  .circle-cont {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 10px;
  }
  .circle-cont  svg {
    display: block;
    position: absolute;
    top: 0;
    left: -65px;
    transform: rotate(-90deg );
  }
  .circle-cont  svg circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s ease-in-out;
    stroke: #d4d4d4;
    stroke-width: 15px;
  }
  .circle-cont  svg circle.bar { stroke: $primary_color; }
  .inner-circle {
    position: absolute;
    left: 50px; top: 50px;
  }
  .inner-inner-circle {
    position: absolute;
    left: 100px; top: 100px;
  }
  .inner-inner-circle3 {
    position: absolute;
    left: 150px; top: 150px;
  }
  .inner-inner-circle4 {
    position: absolute;
    left: 200px; top: 200px;
  }
  .html-items {
    color: #627e89;
    font-size: 14px;
    font-weight: normal;
    margin: 23px 0;
    & > div { display: none; }
  }
  .left-top {
    text-align: right;
    padding: 0px 20px 0 0;
  }
  .left-bottom { 
    position: absolute;
    bottom: 16px; right: 20px;
  }
  .html-item-percent {
    font-size: 15px;
    font-weight: normal;
    border-radius: 5px;
    text-align: center;
    height: 25px;
    width: 39px;
    color: #627e89;
    margin: 20px 0;
  }
}//---------------------------------------------
@media screen and (min-width: $screen-md) and (max-width: $screen-lg - 1){
  .circle-cont svg { left: -125px; }
  .left { width: 38%; }
}//------------------------------------------








/*---------- PORTFOLIO -----------*/
.portfolio {
  .portfolio-img { height: 560px; }
  .portfolio-item {
    position: relative;
    list-style: none;
    background-size: cover !important;
    &:nth-of-type(1) {
      @include make-xs-column(6);
      @include make-sm-column(4);
      @include make-md-column(4);
      @include make-lg-column(5);
    }
    &:nth-of-type(2) {
      @include make-xs-column(6);
      @include make-sm-column(4);
      @include make-md-column(4);
      @include make-lg-column(3);
    }
    &:nth-of-type(3) {
      @include make-xs-column(6);
      @include make-sm-column(4);
      @include make-md-column(4);      
      @include make-lg-column(4);
    }
    &:nth-of-type(4) {
      @include make-xs-column(6);      
      @include make-sm-column(4);
      @include make-md-column(4);
      @include make-lg-column(4);
    }
    &:nth-of-type(5) {
      @include make-xs-column(6);      
      @include make-sm-column(4);
      @include make-md-column(3);
      @include make-lg-column(3);
    }
    &:nth-of-type(6) {
      @include make-xs-column(6);      
      @include make-sm-column(4);
      @include make-md-column(5);
      @include make-lg-column(5);
    }
    &:nth-of-type(7) {
      @include make-xs-column(6);      
      @include make-sm-column(4);
      @include make-md-column(3);
      @include make-lg-column(2);
    }
  }
  .desc-content i {
    font-size: 20px;
    &:first-of-type { float: left; }
    &:last-of-type { float: right; }
  }
  
}//----------------------------------------------
@media screen and (max-width: $screen-xs - 1){
  .portfolio .portfolio-item { width: 100% !important; margin: 2px 0;}
}//---------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .portfolio {
    .portfolio-img { height: 200px; }
    .desc-content { padding: 0 15px 20px; border: 1px solid #ccc; }
    .portfolio-item {
      padding: 0 2px!important;
      margin-bottom: 15px;
      &:last-of-type { margin-bottom: 0; }
    }
    h3, i { color: $primary_color; }
    p { color: $paragraph_color; }
  }
}//----------------------------------------------
@media screen and (min-width: $screen-md){
  .portfolio .portfolio-img { height: 400px; }
}//----------------------------------------------
@media screen and (min-width: $screen-sm){
  .portfolio {
    .portfolio-img { height: 300px; }
    .portfolio-item {
      padding: 0!important;
      &:hover .desc-content {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
  .desc-content {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    background: rgba(236,60,102,0.8);
    padding: 15px; opacity: 0;
    transition: all 0.3s ease-in-out;
    min-width: 200px; max-width: 90%;
    h3, p { color: #fff; text-align: center; }
    h3 { margin-top: 0; margin-bottom: 15px; }
    p { margin-bottom: 20px; }
    i { color: #fff; font-size: 20px; }
  }
}//----------------------------------------------
@media screen and (min-width: $screen-lg){
  .portfolio {
    .portfolio-img { height: 500px; }
    .desc-content { padding: 30px; }
  }
}//----------------------------------------------






/*--------------- TEAM MEMBERS ---------------*/
.team-members {
  .team {
    background: url('../images/backgrounds/team.jpg') center no-repeat;
    background-size: cover;
    padding: 60px 0 0;
    position: relative;
  }
}
.team {
  ul {
    list-style: none;
    padding: 0; margin: 0;
  }
  .members-list {
    @include make-sm-column(6);
    li {
      @include make-xs-column(3);
      @include make-sm-column(4);
      @include make-md-column(3);
      padding: 2px;
      position: relative;
      background-size: 0;
      & > img {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        filter: grayscale(100%);
        width: 100%;
        &.active {
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          -o-filter: grayscale(0%);
          -ms-filter: grayscale(0%);
          filter: grayscale(0%);
        }
        &:hover { cursor: pointer; }
      }
      &:hover .color-img {
        height: 100%; opacity: 1;
        border-bottom: 30px solid rgba(255,255,255,0);
      }
    }
  }
  
  .preview-img {
    display: block;
    @include make-sm-column(6);
    text-align: center;
    & > a { position: relative; display: inline-block; }
  }
  .member-details {
    position: absolute;
    bottom: 0; left: 0;
    padding: 0 0 30px;
    width: 100%;
    .name-designation {
      display: inline-block; 
      background-color: $primary_color;
      padding: 10px 15px;
      box-shadow: 5px 5px 20px 0px #000;
      margin-bottom: 50px;
    }
    h3, h4 {
      font-family: $openSans; font-weight: 600;
      color: #fff; text-transform: uppercase;
    }
    h3 { margin-top: 0; }
    h4 {
      font-weight: 300; font-style: italic;
      margin: 5px 0 0; font-size: 14px;
    }
    a {
      width: 40px; height: 40px;
      border-radius: 50%;
      color: #fff; font-size: 20px;
      display: block; padding: 6px 0;
      transition: transform 0.3s ease;
      &:hover { transform: scale(1.3); }
    }
    ul { display: inline-flex; }
    li { float: left; margin: 0 5px; }
    .dribbble { background-color: #e971a1; }
    .fb { background-color: #0054a5;  }
    .twitter { background-color: #48d6e0; }
    .vimeo { background-color: #008dd2; }
    .youtube { background-color: #f45051; }
    .behance { background-color: #458ccc; }
  }
}//------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: 1599px){
  .team .preview-img, .members-list { float: right; }
}//------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md){
  .team .member-details {
    padding: 0 0 15px;
    h3 { font-size: 18px; margin-bottom: 15px; }
    .name-designation { margin-bottom: 10px; }
  }
}//------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .team-members .team { padding-bottom: 15px; }
  .team .member-details .name-designation { margin-bottom: 20px; }
}//------------------------------------------
@media screen and (max-width: $screen-xs - 1){
  .team {
    .members-list li { width: 33.33%; }
    .member-details {
      padding: 0 0 15px;
      h3 { font-size: 18px; margin-bottom: 15px; }
      .name-designation { margin-bottom: 0px; }
    }
  }
}//------------------------------------------
@media screen and (min-width: 1600px){
  .team {
    padding-bottom: 0!important;
    li { width: 16.66%; }
    .preview-img, .members-list { float: right; }
  }
}//------------------------------------------




/*-------------- CONNECT ---------------*/
.connect {
  ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    position: relative;
  }
  li {
    @include make-xs-column(4);
    text-align: center;
    padding: 50px 15px;
  }
  i {
    width: 60px; height: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
    font-size: 30px;
    padding: 13px 0;
  }
  .social {
    h5, h4, i { color: #fff; }
    a.btn {
      overflow: hidden;
      position: relative;
      span {
        display: block;
        transition: transform 0.3s;
        transform: translateX(0%);
      }
      &:before {
        position: absolute;
        top: 0; left: 0; font-weight: 600;
        padding: 6px 12px;
        width: 100%; height: 100%;
        background: #fff;
        content: attr(data-hover);
        transition: transform 0.3s;
        transform: translateX(-100%);
      }
      &:hover, &:focus { outline: none; }
      &:hover{
        &:before { transform: translateX(0%); }
        span { transform: translateX(100%); }
      }
    }
  }
  .fb {
    background-color: #3b5999;
    .btn:hover:before { color: #3b5999; }
  }
  .twitter {
    background-color: #5fa9dd;
    .btn:hover:before { color: #5fa9dd; }
  }
  .dribbble {
    background-color: #ea4c89;
    .btn:hover:before { color: #ea4c89; }
  }
  .instagram {
    background-color: #1a577d;
    .btn:hover:before { color: #1a577d; }
  }
  .gplus {
    background-color: #dd4b39;
    .btn:hover:before { color: #dd4b39; }
  }
  h5 {
    margin: 20px 0;
    font-family: $lato;
    font-weight: 400;
    font-size: 20px;
  }
  h4 {
    margin: 40px 0;
    font-family: $lato;
    font-weight: 300;
    font-size: 25px;
  }
  .btn {
    border: 1px solid #fff;
    min-width: 120px;
    font-size: 16px;
    margin-top: 30px;
    transition: all 0.3s ease-in-out;
  }
}//-----------------------------------------
@media screen and (min-width: $screen-sm){
  .connect li { width: 20%; float: left; }
}//-------------------------------------------
@media screen and (max-width: $screen-md - 1){
  .connect {
    .container { width: 100%; }
    li { padding: 30px 10px; }
    h4 { margin: 30px 0; }
    h5 { margin: 10px 0; }
    .btn { margin-top: 0; }
  }
}//---------------------------------------------
@media screen and (max-width: $screen-xs - 1){
  .connect {
    li {
      width: 100%; padding: 30px 5px;
      & > div { width: 50%; float: left; }
    }
    h4 { margin: 0; }
    .btn { margin-top: 15px; }
  }
}//-----------------------------------------



/*----------- BLOG POSTS -------------*/
.blogs { position: relative; }
.post {
  border: 1px solid #bfc3cd;
  margin-bottom: 60px;
  display: block;
  overflow: hidden;
  &:hover .fig { transform: scale(1.1); }
  h2 {
    margin: 0;
    text-align: center;
    font-size: 70px;
    font-weight: 400;
    font-family: $lato;
    text-transform: uppercase;
    line-height: 0.4;
    color: $primary_color;
    letter-spacing: -5px;
    span { font-size: 16px; letter-spacing: 0; }
  }
  h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 25px;
    text-transform: uppercase;
    color: $secondary_grey;
    font-weight: 700;
    font-family: $lato;
  }
  h5 {
    text-transform: uppercase;
    color: #333;
    span { color: $primary_color; }
  }
  p {
    line-height: 1.8;
    color: $paragraph_color;
    font-weight: 400;
    font-family: $lato;
    font-size: 15px;
  }
  background-color: #f8f8f8;
  .date-column {
    @include make-xs-column(3);
    @include make-sm-column(1);
    padding: 0;
  }
  .date-tag {
    border-left: 1px solid #bfc3cd;
    border-bottom: 1px solid #bfc3cd;
    border-right: none;
    background-color: #fff;
    padding: 50px 5px;
  }
  .desc {
    @include make-xs-column(9);
    @include make-sm-column(6);
    padding: 40px;
  }
  .fig {
    @include make-xs-column(12);
    @include make-sm-column(5);
    padding: 0;
    background-size: cover!important;
    transform: scale(1);
    transition: transform 0.3s ease;
  }


  .author { margin-top: 30px; }
  .auth-img {
    @include make-xs-column(2);
    padding-right: 0;
    img { border-radius: 50%; }
  }
  .auth-name {
    border-right: 1px solid #b7b7b7;
    @include make-xs-column(5);
  }
  h5 {
    font-family: $openSans;
    font-weight: 400;
    font-size: 13px;
  }
  .designation {
    text-transform: capitalize;
    font-style: italic;
    color: $paragraph_color;
  }
  .likes {
    @include make-xs-column(4);
    h5 { margin: 15px 0; color: $paragraph_color; }
  }
  .fa-heart {
    font-size: 28px;
    margin-right: 10px;
    color: $primary_color;
    vertical-align: middle;
  }
  &:last-of-type { margin-bottom: 0; }
}//----------------------------------------
@media screen and (max-width: $screen-lg - 1){
  .post h2 { font-size: 55px; letter-spacing: -2px; }
}//----------------------------------------
@media screen and (max-width: $screen-md - 1){
  .blog-post {
    .container { width: 100% }
    .post {
      margin-left: 0; margin-right: 0;
      h2 { font-size: 50px; }
    }
  }
}//---------------------------------------------
@media screen and (max-width: $screen-sm - 1){
  .post {
    .fig { height: 300px; }
    .date-column { float: right; }
    .desc { padding: 20px; }
  }  
}//---------------------------------------------
@media screen and (min-width: $screen-sm){
  .post:nth-of-type(odd) {
    .date-tag {
      border-right: 1px solid #bfc3cd;
      border-bottom: 1px solid #bfc3cd;
      border-left: none;
    }
    .fig, .desc, .date-column { float: right; }
  }
}//---------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1){
  .post .desc { padding: 30px; }
}//---------------------------------------------
@media screen and (max-width: $screen-xs - 1){
  .post {
    position: relative;
    .fig { height: 200px !important; }
    .date-column {
      position: absolute;
      top: 0; right: 0;
      width: auto;
    }
    .date-tag { padding: 20px 5px; }
    .desc { width: 100%; }
    .likes {
      width: 100%; text-align: right;
      h5 { margin: 0; }
    }
    .auth-img { width: 25%; }
    .auth-name { width: 75%; border: none; }
  }
}//---------------------------------------------



/*------------ PRICING TABLE -------------*/
.pricing-table ul {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}
.price-table {
  &.row { margin-bottom: 120px; }
  & > li {
    @include make-sm-column(4);
    @include make-lg-column(3);
    padding: 0 10px;
  }
  .price-faq {
    padding: 30px 15px;
    background-color: #fff;
    border: 1px solid #666;
    h3 { color: #555; }
    h4 {
      color: #666;
      font-family: $lato;
      font-weight: 600;
      font-size: 16px;
      margin: 30px 0 20px;
    }
  }
  p {
    color: $paragraph_color;
    font-family: $lato;
    font-weight: 400;
    font-size: 15px;
  }
  .list-item h3 {
    margin-top: 0;
    font-family: $lato;
    font-weight: 400;
    font-size: 16px;
  }
}
.plan {
  padding: 30px 40px;
  background-color: #535968;
  position: relative;
  h2 {
    margin-bottom: 50px;
    font-family: $lato;
    font-weight: 600;
    font-size: 40px;
    span { font-size: 20px; font-size: 20px; }
  }
  li {
    color: #fff;
    margin: 20px 0;
    font-family: $lato;
    font-weight: 400;
    font-size: 16px;
  }
  &.active { background-color: $primary_color; }
  .btn {
    min-width: 150px;
    border: 1px solid #fff;
    margin-top: 50px;
    font-family: $lato;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;

    overflow: hidden;
    span {
      position: relative;
      display: inline-block;
      transition: transform 0.3s;
    }
    &:before {
      position: absolute;
      top: 100%; font-weight: 700;
      content: attr(data-hover);
      transform: translate3d(0,100%,0);
      line-height: 34px;
      transition: transform 0.3s;
    }
    &:hover, &:focus { outline: none; }
    &:hover {
      span { transform: translateY(-150%); }
      &:before { transform: translate3d(0,-100%,0); }
    }
  }
  h2, h3 { color: #fff; }
}
//-----------------------------------------
@media screen and (min-width: $screen-lg) {
  .price-table > li {
    width: 26%;
    &:last-of-type { width: 22%; }
  }
}//----------------------------------------
@media screen and (max-width: $screen-lg - 1) {
  .price-table {
    & > li:first-of-type { width: 100%; margin-bottom: 15px; }
    .price-faq {
      padding: 15px;
      h4 { margin: 20px 0 10px; }
    }
  }
}//----------------------------------------
@media screen and (max-width: $screen-md - 1) {
  .pricing-table .container { width: 100%; }
  .plan .btn { margin-top: 30px; }
}//----------------------------------------
@media screen and (min-width: $screen-xs) and (max-width: $screen-sm - 1) {
  .plan {
      padding: 30px;
    & > div { float: left; width: 50%; }
  }
  .price-table ul { float: right; width: 50%; border-left: 1px solid #999; padding-left: 20px;}
}//----------------------------------------
@media screen and (max-width: $screen-sm - 1) {
  .price-table > li { margin-bottom: 15px; text-align: center; }
}//----------------------------------------
@media screen and (max-width: $screen-xs - 1) {
  .plan {
    h2 { margin-bottom: 30px; margin-top: 10px; }
    li { margin: 10px 0; }
  }
}//----------------------------------------



/*--------- FOR EVERYONE --------------*/
.for-everyone {
  background-color: $primary_color;
  .fig {
    @include make-md-column(6);
    .main-img { margin-left: -30px; }
    position: relative;
  }
  .desc { @include make-md-column(6); }
  h2 {
    font-size: 115px;
    font-family: $lato;
    font-weight: 300;
    letter-spacing: -3px;
    line-height: 0.8;
  }
  h4 {
    font-size: 20px;
    font-family: $lato;
    font-weight: 300;
  }
  h2, h4 { color: #fff }
}//----------------------------------------------
@media screen and (max-width: $screen-md - 1) {
  .for-everyone {
    text-align: center;
    .desc { margin-top: 25px; }
  }
}//-------------------------------------------
@media screen and (min-width: $screen-md) {
  .for-everyone .desc { text-align: right; margin-top: 40px; }
}//-------------------------------------------


/*-------------FOOTER--------------*/
.map-wrapper,
#map-canvas { height: 400px; }
.map-wrapper {
  padding: 25px 25px 0;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
footer {
  .upper-footer {
    background-size: cover!important;
    background: url('../images/backgrounds/footer.jpg') center no-repeat;
    i { color: $primary_color; margin-right: 8px; }
  }
  .fa { font-size: 22px; }
  .lower-footer { background-color: #1d1d1d; }
  ul { padding: 0; margin: 0; list-style: none; }
  li {
    float: left;
    a {
      border-radius: 50%;
      background-color: #fff;
      color: $primary_color;
      width: 35px; height: 35px;
      margin: 0 8px 0 5px;
      padding: 7px 0;
      display: inline-block;
      text-align: center;
    }
  }
  .footer-social, .copyright { padding-top: 25px; padding-bottom: 25px; }
  .contact, .map-area { padding-top: 160px; }

  .footer-social, .map-area {
    @include make-sm-column(7);
    @include make-md-column(7);
  }
  .contact, .copyright {
    @include make-sm-column(5);
    @include make-md-column-offset(1);
    @include make-md-column(4);
  }
  .map-area {
    padding-right: 3px;
    img { width: 100%; }
  }
  p {
    color: #c8c8c8;
    margin-bottom: 40px;
    font-size: 15px;
    font-family: $lato;
    font-weight: 400;
  }
  h4, h5 { color: #fff; }
  h4, h5, h6 { font-family: $lato; font-weight: 400; }
  h4 { font-size: 20px; }
  h5 { font-size: 15px; }
  .copyright a { color: #787878; font-size: 13px; }
  .footer-social { background-color: $primary_color; }
  .contact { padding-bottom: 5px; }

  a {
    transition: all 0.3s ease;
    &:hover { color: #fff; }
    &.dribbble:hover { background-color: #e971a1; }
    &.fb:hover { background-color: #0054a5; }
    &.rss:hover { background-color: #F26109; }
    &.gplus:hover { background-color: #DD4B38; }
    &.twitter:hover { background-color: #48d6e0; }
    &.vimeo:hover { background-color: #008dd2; }
    &.youtube:hover { background-color: #f45051; }
    &.behance:hover { background-color: #458ccc; }
  }
}//----------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-lg - 1) {
  footer {
    .contact, .map-area { padding-top: 60px; }
    .map-area {
      position: relative;
      img { position: absolute; bottom: 0; width: 96%; }
    }
  }
}//----------------------------------------------
@media screen and (min-width: $screen-md) and (max-width: $screen-lg - 1) {
  footer .map-area img { width: 97%; }
}//----------------------------------------------
@media screen and (min-width: $screen-sm) {
  footer .upper-footer {
    .contact, .map-area { float: right; }
  }
}//----------------------------------------------
@media screen and (max-width: $screen-sm - 1) {
  footer {
    text-align: center;
    .contact, .map-area { padding-top: 30px; }
    .map-area { padding-right: 15px; }
    .map-wrapper { padding: 15px 15px 0; }
    .copyright, .footer-social { padding-top: 15px; padding-bottom: 15px; }
    .footer-social ul { display: inline-block; }
  }
  .map-wrapper, #map-canvas { height: 250px; }
}//----------------------------------------------





/*=============== BLOG LIST PAGE ====================*/
/* ------------- IMAGE LIST POSTS -----------------*/
.image-post {
  background-color: #f1f1f1;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
  overflow: hidden;  
  &:last-of-type { margin-bottom: 0; }
  & > i {
    position: absolute;
    right: 0; bottom: 45px;
    width: 50px; height: 50px;
    text-align: center;
    background-color: $primary_color;
    padding: 13px 0; font-size: 26px;
    color: #fff; margin: 0;
  }
  .fig {
    position: relative;
    @include make-md-column(8);
    background-size: cover;    
  }
  .desc {
    @include make-md-column(4);
    padding: 30px 40px;
    p {
      font-family: $openSans;
      color: $paragraph_color;
      margin: 30px 0; line-height: 1.8;
      &.blockquote { font-style: italic; }
    }
  }
  .date-block {
    position: absolute;
    left: 0; top: 0; padding: 20px;
    background-color: $primary_color;
    h5 {
      color: #fff; margin: 0;
      font-size: 15px;
      font-family: $openSans;
      font-weight: 600; line-height: 1.2;
    }
  }
  .likes-comments {
    position: relative;
    margin-bottom: 15px;
    h5 { float: left; margin-right: 15px; color: #333; }
    i {
      color: $primary_color;
      margin-right: 5px; font-size: 26px;
      vertical-align: middle;
    }
  }
  h3 {
    font-family: $openSans;
    font-size: 30px; font-weight: 700;
    margin: 30px 0 40px;
    text-transform: uppercase;
    color: #6b707f;
  }
}//----------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
  .image-post .fig { height: 350px!important; }
}//----------------------------------------------
@media screen and (max-width: $screen-sm - 1) {
  .image-post {
    margin-top: 0;
    .fig { height: 300px; }
  }
  .related-articles .article-item { overflow: hidden; }
}//----------------------------------------------
@media screen and (max-width: $screen-xs - 1) {
  .image-post {
    .desc { padding: 15px 30px 15px 15px; }
    .likes-comments { margin-bottom: 0; }
    & > a { bottom: 50px; }
    .fig { height: 200px; }
  }
}//----------------------------------------------
@media screen and (min-width: $screen-md) {
  .post-image, .video-post, .gallery-post {
    .image-post {
      &:hover {
        .gallery-midblock,
        .fig { transform: scale(1.02); }
      }
      .fig, .gallery-midblock {
        transform: scale(1);
        transition: transform 0.3s ease;
        transform-origin: top left;
      }
    }
  }
}//----------------------------------------------


.author-details {
  .auth-img {
    @include make-xs-column(4);
    max-width: 120px;
    img { border-radius: 50%; }
  }
  .auth-name {
    @include make-xs-column(8);
    padding-left: 0; margin-top: 10px;
  }
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400; font-size: 14px;
    span { color: $primary_color; }
  }
  .name { text-transform: uppercase; color: #333; }
  .designation {
    text-transform: capitalize;
    font-style: italic;
    color: #828696;
  }
}



/* ------------- TEXT LIST POSTS -----------------*/
.text-post {
  .desc { @include make-md-column(10); }
  .fig {
    @include make-md-column(2);
    height: auto!important;
  }
}//----------------------------------------------
@media screen and (max-width: $screen-sm - 1) {
  .text-post .date-block {
    position: relative;
    h5 { display: inline-block; }
  }
  .text-post .fig { padding: 0; }
}//----------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
  .text-post .date-block {   right: 0; left: auto; }
}//----------------------------------------------



/* ------------- VIDEO POSTS -----------------*/
.video-post .image-post { overflow: hidden; }
//----------------------------------------------



/*------------- TWITTER POST -------------*/
.post-twitter {
  padding: 20px 40px;
  border-radius: 5px;
  background-color: #fff;
  .fig {
    @include make-xs-column(4);
    @include make-sm-column(3);
    @include make-md-column(2);
    img { border-radius: 4px; }
  }
  .desc {
    h4, h5, p { color: $paragraph_color; }
    @include make-xs-column(8);
    @include make-sm-column(9);
    @include make-md-column(10);
    & > a {
      float: right;
      background-color: #f4f4f4;
      padding-right: 20px;
      border-radius: 4px;
      border: 1px solid #b8b8b8;
      font-size: 12px;
      color: #7c7c7c;
      i {
        background-color: #68bfe4;
        color: white;
        font-size: 22px;
        padding: 5px 10px;
        vertical-align: sub;
        margin-right: 10px;
      }
    }
    p a {
      float: right; margin-left: 30px;
      font-size: 20px;
      color: $paragraph_color;
    }
  }
}
.twitter-post .post-wrapper {
  background-color: $primary_color;
  padding: 40px 80px; position: relative;
}//-----------------------------------------------
@media screen and (max-width: $screen-sm - 1) {
  .twitter-post .post-wrapper { padding: 30px; }
  .post-twitter {
    padding: 15px;
    .desc {
      width: 100%;
      p a { margin-left: 15px; }
    }
    .fig { width: auto; }
  }
}//-----------------------------------------------
@media screen and (max-width: $screen-xs - 1) {
  .twitter-post .post-wrapper { padding: 15px; }
  .post-twitter { padding: 15px 0; }
}//-----------------------------------------------
@media screen and (min-width: $screen-xs) {
  .post-twitter .desc p { display: inline; }
}//-----------------------------------------------



/*----------- GALLERY POST ------------*/
.gallery-post {
  .fig {
    @include make-xs-column(5);
    @include make-sm-column(6);
    @include make-md-column(3);
    height: 300px;
  }
  .desc {
    @include make-md-column(4);
    padding: 30px 40px;
  }
}
.gallery-midblock {
  @include make-xs-column(7);
  @include make-sm-column(6);
  @include make-md-column(5);
  padding: 0; 
  & > div { @include make-xs-column(6); height: 150px; }
}//---------------------------------------
@media screen and (max-width: $screen-md - 1) {
  .gallery-post .fig, .gallery-midblock { margin-bottom: 30px; }
  .gallery-post .fig { height: 300px; }
  .gallery-midblock > div { height: 150px; }
}//---------------------------------------
@media screen and (max-width: $screen-xs - 1){
  .gallery-post .desc { padding: 15px 30px 15px 15px; }
}//---------------------------------------



/*------------- AUDIO POST --------------*/
.audio-post {
  margin-bottom: 120px;
  .fig {
    background-color: $primary_color;
    text-align: center;
    img { margin: 110px 0; }
  }
}





/*=============== BLOG DETAILS PAGE ====================*/
.blog-details {
  ul { padding: 0; list-style: none; }
  .image-post {
    .fig, .desc { @include make-md-column(12); }
  }
  .blog-part {
    @include make-md-column(8);
    & > div { margin-bottom: 60px; margin-top: 0; }
  }
  .news-widget {
    .nav-tabs > li {
      &:first-of-type {
        width: 55%;
        a { margin-right: 2px; margin-left: 0; }
      }
      &:last-of-type {
        width: 45%;
        a { margin-left: 2px; margin-right: 0; }
      }
      a {
        color: #fff;
        font-family: $openSans;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 25px 0;
        text-align: center;
        border-radius: 0;
        background-color: $primary_color;
        border: 1px solid $primary_color;
      }
      &.active a {
        color: #6b707f;
        background-color: transparent;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        &:hover { border-bottom: 1px solid #fff; }
      }
    }
  }
}

.widget-heading {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #6b707f;
  padding-bottom: 30px;
}

.widget-part {
  @include make-md-column(4);
  .widget { margin-bottom: 30px; }
  .widget-heading { border-bottom: 1px solid #ccc; }
  .news-img {
    @include make-xs-column(4);
    img { width: 100%; height: auto; padding-left: 0; }
  }
  .news-desc {
    @include make-xs-column(8);
    padding: 0;
    h6 {
      color: $primary_color;
      span {
        padding-left: 15px; margin-left: 15px;
        border-left: 1px solid $primary_color;
      }
    }
  }
  .news-list {
    padding: 0 25px;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    a { display: inline-block; }
    li {
      padding: 30px 0;
      border-bottom: 1px solid #dbdbdb;
      &:last-of-type { border: none; }
    }
    h4, h6 { font-family: $openSans; }
    h4 { color: #6b707f; margin-top: 0; }
    h6 { font-weight: 600; }
  }
}

.category-widget {
  padding: 20px;
  border: 1px solid #ccc;
  .panel-default { border: none; }
  .panel-heading {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    a {
      color: #666;
      font-family: $openSans;
      font-weight: 600;
      i.fa-minus { display: inline-block; }
      i.fa-plus { display: none; }
      &.collapsed {
        i.fa-minus { display: none; }
        i.fa-plus { display: inline-block; }
      }
      i {
        margin-right: 10px;
        border: 2px solid #ccc;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 3px 0;
        display: inline-block;
        font-size: inherit;
      }
    }
  }
  .panel-collapse { overflow: hidden; }
  .inner-list-items {
    padding-left: 35px;
    li { margin: 10px 0; }
    a {
      color: #666;
      font-family: $openSans;
      &:hover { font-weight: 600; }
    }
  }
}
//------------------------------------------------------
@media screen and (min-width: $screen-xs) {
  .blog-details .image-post {
    .fig { height: 300px; }
  }
}//------------------------------------------------------
@media screen and (min-width: $screen-md) {
  .blog-details .image-post {
    background-color: transparent;
    .fig { height: 350px; }
    .desc { padding-left: 0; padding-right: 0; }
  }
}//------------------------------------------------------
@media screen and (max-width: $screen-xs - 1) {
  .blog-details .image-post {
    .fig { height: 200px; }
  }
  .image-post .date-block { padding: 12px; }
}//------------------------------------------------------



.comment-section {
  .comment-list{
    list-style: none;
    .layer1 {
      padding: 30px 0;
      border-bottom: 1px solid #ccc;
    }
    .layer2 { margin-left: 50px; }
  }
  .layer2 {
    margin: 40px 0;
    &:last-of-type { margin-bottom: 0; }
  }
}
.comment-img {
  @include make-xs-column(3);
  @include make-sm-column(2);
  @include make-md-column(2);
  img { width: 100%; }
}
.comment-desc {
  @include make-xs-column(9);
  @include make-sm-column(10);
  @include make-md-column(10);
}
.comment-details {
  @include make-xs-column(12);
  @include make-sm-column(9);
  @include make-md-column(12);
  @include make-lg-column(9);
  padding-left: 0;
  h4, h5, p { font-family: $openSans; }
  h4 { margin-top: 0; font-weight: 600; }
  h5 { color: $primary_color; }
  p { color: $paragraph_color; }
}
.comment-likes-reply {
  @include make-xs-column(12);
  @include make-sm-column(3);
  @include make-md-column(12);
  @include make-lg-column(3);
  padding: 0;
  a {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 15px 10px;
    display: inline-block;
    margin: 10px 5px;
    color: #666;
  }
  i { color: $primary_color; font-size: 22px; vertical-align: sub; }
}


.contact-comment {
  .input-wrapper {
    @include make-sm-column(6);
    margin: 30px 0;
  }
  input, textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px 25px;
    resize: none;
  }
  .btn {
    width: auto;
    float: right;
    background-color: $primary_color;
    color: #fff;
    font-family: $openSans;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 15px 60px;
  }
  .btn-wrapper { margin: 24px 0; }
  .textArea-wrapper { position: relative; }
}


.related-articles {
  margin-top: 60px;
  .article-wrapper {
    @include make-xs-column(6);
    @include make-md-column(4);
    margin-bottom: 30px;    
  }
  .article-item {
    position: relative;
    display: block;
    &:hover .item-img { transform: scale(1.1); }
  }
  .item-img {
    height: 200px;
    transition: transform 0.5s ease-in-out;
  }
  .details {
    background-color: $primary_color;
    padding: 20px;
    
    h3, h4, h5, i { color: #fff; font-weight: 400; }
    .likes-comments { margin-bottom: 0; }
    h3 { margin: 25px 0; font-size: 20px; }
    h4 { margin-top: 0; font-size: 15px; }
    h5 { margin-top: 0; margin-bottom: 0; }
    i { font-size: 18px; }
  }
}//-----------------------------------------------
@media screen and (min-width: $screen-sm) {
  .related-articles {
    .item-img { height: 300px; }
    .details {
      width: 80%;
      position: absolute;
      bottom: -75px; left: 10%;
    }
    .article-wrapper { margin-bottom: 150px; }
  }
}//-----------------------------------------------
@media screen and (min-width: $screen-md) {
  .related-articles .item-img { height: 400px; }
}//-----------------------------------------------
@media screen and (max-width: $screen-xs - 1) {
  .related-articles .article-wrapper { width: 100%; }
}//-----------------------------------------------


.pageAd img { width: 100%; }


.testimonials {
  padding: 20px 30px;
  border: 1px solid #ccc;
  ul { padding: 0; list-style: none; }
  .testi-img {
    @include make-xs-column(3);
    @include make-sm-column(2);
    @include make-md-column(3);
    margin-bottom: 15px;
    img {  width: 100%; }
  }
  .testi-details {
    @include make-xs-column(9);
    @include make-sm-column(10);
    @include make-md-column(12);
  }
  p {
    font-family: $openSans;
    color: $paragraph_color;
  }
  h6 {
    font-family: $lato;
    text-align: right;
    font-style: italic;
    color: $primary_color;
  }
  h6, p { font-size: 13px; }
  li { margin-top: 30px; margin-bottom: 30px; }
}//-----------------------------------------------
@media screen and (min-width: $screen-md) {
  .testimonials {
    li.row { margin-left: 0; margin-right: 0; }
    .testi-img {
      position: relative;
      margin-right: 85px;
      padding: 0;
      &:after {
        content: '';
        height: 100%; width: 80px;
        top: 0; left: 100%;
        position: absolute;
        background: url('../images/open-quote.png') center no-repeat;;
      }
    }
    .testi-details { padding: 0; }
  }
}//-----------------------------------------------




.overlay {
  position: absolute;
  width: 100%;
  height: 0%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -10;
  &.disp { z-index: 9999; height: 100%; }
}
.modal {
  font-weight: 400;
  border-top: 5px solid $primary_color;;
  background-color: #f3f3f3;
  position: absolute;
  top: 100px; left: 50%;
  overflow: hidden;
  bottom: auto;
  padding: 0px 50px 30px;
  & > i {
    position: absolute;
    right: 0; top: 0;
    font-size: 20px;
    color: #fff; padding: 5px;
    background-color: #dd4f45;
    &:hover { cursor: pointer; }
  }
  .logo { position: relative; }
  h2 { text-transform: uppercase; }
  h2, h3 {
    color: $primary_color;
    font-family: $lato;
  }
  h1 {
    text-indent: -9999px;
    width: 166px; height: 166px;
    display: inline-block;
    background-position: center;
    border-radius: 50%; margin: 0;
  }
  & > p {
    margin-bottom: 30px;
    color: $paragraph_color;
  }
  input { width: 100%; padding: 10px 15px; }
  button, h5 { float: right; }
  button {
    font-size: 16px; padding: 10px 40px;
    border: none; margin: 0;
  }
  .fb-login {
    background-color: #3b579d;
    i {
      border-right: 1px solid #334b87;
      padding: 15px 40px;
    }
  }
  .twitter-login {
    background-color: #5fa9dd;
    i { border-right: 1px solid #5293c1; }
  }
}
.form-btn-wrapper {
  margin: 30px 0;
  h5 { margin-right: 40px; }
}
.existing-login {
  a {
    display: block; padding: 0;
    text-align: center;
    text-transform: capitalize;
  }
  span {
    padding: 20px 0 18px;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    letter-spacing: 2px;
  }
  i {
    color: #fff; float: left;
    font-size: 30px;
    padding: 15px 32px;
  }
}
.existing-login > div,
.login-page form .input-wrapper {
  @include make-xs-column(12);
  @include make-sm-column(6);
}
//----------------------------------------------------------
@media screen and (min-width: $screen-md){
  .login-page.modal { width: 950px; margin-left: -475px; }
}//----------------------------------------------------------
@media screen and (min-width: $screen-sm){
  .login-page.modal {
    h2 { margin-top: 30px; }
    h3 { margin: 40px 0 30px; }
  }
}//----------------------------------------------------------
@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
  .existing-login i { padding: 15px 20px; }
  .login-page.modal {
    width: 724px; margin-left: -362px;
    .fb-login i { padding: 15px 24px; }
  }
}//----------------------------------------------------------
@media only screen and (max-width: $screen-sm - 1){
  .login-page.modal {
    width: 90%; margin-left: -45%;
    padding: 0 30px 20px; top: 50px;
    .existing-login > div,
    .input-wrapper { margin: 10px 0; }
  }
}//----------------------------------------------------------




// ============ CONTACT US PAGE =================
.contactUs {
  .contact-comment {
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #eee;
    position: relative;
  }
  .input-holder { position: relative; }
}
// .contact-page-form { float: left; }
form .form-message {
  display: none;
  float: right;
  margin-top: 16px 60px 16px 0px;
  font-size: 18px;
  font-family: $openSans;
}
/*======LOADER===========*/
.loader {
  margin: 6px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 7px solid rgba(236, 60, 102, 0.9);
  border-right: 7px solid rgba(236, 60, 102, 0.9);
  border-bottom: 7px solid rgba(236, 60, 102, 0.9);
  border-left: 7px solid #eee;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
@keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/*========================*/

label.error {
  position: absolute;
  right: 30px; top: 10px;
  font-size: 12px;
  transform: translateY(-50%);
  margin: 0; color: red;
}





// ======= WAYPOINT ==============
@media screen and (min-width: $screen-md) {
  .below-banner .container,
  .search-share .share li,
  .expertise-wrapper img,
  .tech-used .fig img,
  .tech-used h3.head,
  .tech-used h3.sub-head,
  .about-us .form-btn,
  .blog-post .post,
  .price-table .price-wrapper,
  .for-everyone .smartphone,
  .connect li,
  .text-post .image-post,
  .video-post .image-post .fig { opacity: 0; }

  .below-banner .container.animated,
  .search-share .share li.animated,
  .expertise-wrapper img.animated,
  .tech-used .fig img.animated,
  .tech-used h3.head.animated,
  .tech-used h3.sub-head.animated,
  .about-us .form-btn.animated,
  .blog-post .post.animated,
  .price-table .price-wrapper.animated,
  .for-everyone .smartphone.animated,
  .connect li.animated,
  .text-post .image-post.animated,
  .video-post .image-post .fig.animated { opacity: 1; }
}

@media screen and (max-width: $screen-md) {
  .below-banner .container,
  .search-share .share li,
  .expertise-wrapper img,
  .tech-used .fig img,
  .tech-used h3.head,
  .tech-used h3.sub-head,
  .about-us .form-btn,
  .blog-post .post,
  .price-table .price-wrapper,
  .for-everyone .smartphone { opacity: 1; }
}