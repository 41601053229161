/*======= FONT FACE ========*/
// @mixin font-face($font-family, $font-weight: normal, $font-style: normal) {
//   @font-face {
//     font-family: $font-family;
//       src: url('/fonts/#{$font-family}.eot');
//       src: url('/fonts/#{$font-family}.eot?#iefix') format('embedded-opentype'),
//            url('/fonts/#{$font-family}.woff') format('woff'),
//            url('/fonts/#{$font-family}.ttf') format('truetype'),
//            url('/fonts/#{$font-family}.svg##{$font-family}') format('svg');
//     font-weight: $font-weight;
//     font-style: $font-style;
//   }
//   // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
//   @media screen and (-webkit-min-device-pixel-ratio: 0) {
//     @font-face {
//       font-family: $font-family;
//         src: url('/fonts/#{$font-family}.svg##{$font-family}') format('svg');
//     }
//   }
// }




@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
           url('#{$file-path}.woff') format('woff'),
           url('#{$file-path}.ttf') format('truetype'),
           url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
  // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
      font-family: $font-family;
        src: url('#{$file-path}.svg##{$font-family}') format('svg');
    }
  }
}

@include font-face(Ubuntu-Bold, 'https://dl.dropbox.com/u/1220078/ubuntu-new/Ubuntu-Bold-webfont', 700, normal);

@mixin Ubuntu-Bold {
  font-family: 'Ubuntu-Bold', arial, sans-serif;
  font-weight: 700; font-style: normal;
}
